<template>
  <div>

    <b-alert
      variant="danger"
      :show="company === undefined"
    >
      <h4 class="alert-heading">
        Error fetching company data
      </h4>
      <div class="alert-body">
        No company found with this company id. Check
        <b-link
          class="alert-link"
          :to="{ name: 'companies'}"
        >
          Companies List
        </b-link>
        for other companies.
      </div>
    </b-alert>

    <template v-if="company && loaded">
      <b-form @submit.prevent="updateCompanyInfo">

        <b-card-code
          title="Company Details"
        >
          <b-row>
            <b-col sm="12">
              <div
                class="brand-photo"
                style="width: 140px; height: 140px; margin: 20px auto; border-radius: 50%; overflow: hidden; cursor: pointer; border: 1px solid #ccc;"
              >
                <input
                  ref="uploadClientImage"
                  type="file"
                  style="display:none"
                  accept="image/*"
                  @change="handleClientImage($event)"
                >
                <div @click="pickUpClientImage">
                  <img
                    v-if="uploadedImage"
                    :src="uploadedImage"
                    :alt="company.name"
                    width="140px"
                    height="140px"
                    class="mx-auto"
                  >
                  <img
                    v-else-if="company.logo"
                    :src="company.logo"
                    :alt="company.name"
                    width="140px"
                    height="140px"
                    class="mx-auto"
                  >
                  <img
                    v-else
                    src="./../assets/images/logo/logo.png"
                    width="140px"
                    height="140px"
                    class="mx-auto"
                    :alt="company.name"
                  >
                </div>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="4">
              <b-form-group
                label="Company Name"
              >
                <b-input-group class="input-group-merge">
                  <b-input-group-prepend is-text>
                    <feather-icon icon="UserIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    v-model="company.name"
                    placeholder="Company Name"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>

            <b-col md="4">
              <b-form-group
                label="Phone Number"
              >
                <b-form-input
                  v-model="company.phone"
                  placeholder="Phone Number"
                />
              </b-form-group>
            </b-col>

            <b-col md="4">
              <b-form-group
                label="Website"
              >
                <b-input-group class="input-group-merge">
                  <b-input-group-prepend is-text>
                    <feather-icon icon="LinkIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    v-model="company.website"
                    placeholder="Website"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>

            <b-col md="4">
              <b-form-group
                label="Number of Employees"
              >
                <b-input-group class="input-group-merge">
                  <b-input-group-prepend is-text>
                    <feather-icon icon="UsersIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    v-model="company.employeesNumber"
                    type="number"
                    placeholder="Number of Employees"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>

            <b-col md="4">
              <b-form-group
                label="Email Address"
              >
                <b-input-group class="input-group-merge">
                  <b-input-group-prepend is-text>
                    <feather-icon icon="MailIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    v-model="company.email"
                    placeholder="Email Address"
                  />
                </b-input-group>
                <!-- <div
                  class="form-label-group"
                >
                  <b-form-tags
                    v-model="company.email"
                    input-id="tags-basic"
                    class="mb-2"
                    placeholder="Add Email"
                  />
                </div> -->
              </b-form-group>
            </b-col>

            <b-col md="4">
              <b-form-group
                label="Address"
              >
                <b-input-group class="input-group-merge">
                  <b-input-group-prepend is-text>
                    <feather-icon icon="MapPinIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    v-model="company.location.address"
                    placeholder="Address"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>

            <b-col md="4">
              <b-form-group
                label="Country"
              >
                <v-select
                  v-model="company.location.country"
                  placeholder="Country"
                  :options="countryArr"
                  @input="selectState"
                />
              </b-form-group>
            </b-col>

            <b-col md="4">
              <b-form-group
                label="State"
              >
                <v-select
                  v-model="company.location.state"
                  placeholder="State"
                  :disabled="!company.location.country"
                  :options="states"
                />
              </b-form-group>
            </b-col>

            <b-col md="4">
              <b-form-group
                label="Zip code"
              >
                <b-input-group class="input-group-merge">
                  <b-input-group-prepend is-text>
                    <feather-icon icon="FramerIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    v-model="company.location.zip"
                    placeholder="Zip code"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>

            <b-col md="4">
              <b-form-group
                label="Established date"
              >
                <b-form-datepicker
                  v-model="company.establishedDate"
                  :initial-date="company.establishedDate"
                  :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                  placeholder="Established date"
                  class="mb-1"
                />
              </b-form-group>
            </b-col>

            <b-col md="4">
              <b-form-group
                label="Industry Categories"
              >
                <div class="form-label-group">
                  <v-select
                    v-model="company.industries"
                    placeholder="Industry Categories"
                    multiple
                    label="name"
                    :options="categories"
                    @input="setSubCategories"
                  />
                </div>
              </b-form-group>
            </b-col>

            <b-col md="4">
              <b-form-group
                label="Industry Sub-Categories"
              >
                <div class="form-label-group">
                  <v-select
                    v-model="company.subCategories"
                    :disabled="!company.industries || !company.industries.length"
                    placeholder="Industry Sub-Categories"
                    multiple
                    :options="subCategories"
                  />
                </div>
              </b-form-group>
            </b-col>

            <b-col md="4">
              <b-form-group
                label="Stock Ticker Symbol (If available)"
              >
                <div class="form-label-group">
                  <b-input-group class="input-group-merge">
                    <b-input-group-prepend is-text>
                      <feather-icon icon="BarChart2Icon" />
                    </b-input-group-prepend>
                    <b-form-input
                      v-model="company.stockName"
                      placeholder="Stock Name"
                    />
                  </b-input-group>
                </div>
              </b-form-group>
            </b-col>

            <b-col md="8">
              <b-form-group
                label="Short Description"
              >
                <div class="form-label-group">
                  <b-input-group class="input-group-merge">
                    <b-input-group-prepend is-text>
                      <feather-icon icon="InfoIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      v-model="company.about"
                      placeholder="Short Description"
                    />
                  </b-input-group>
                </div>
              </b-form-group>
            </b-col>

            <b-col md="12">
              <b-form-group
                label="Long Description"
              >
                <div class="form-label-group">
                  <b-input-group class="input-group-merge">
                    <b-input-group-prepend is-text>
                      <feather-icon icon="InfoIcon" />
                    </b-input-group-prepend>
                    <b-form-textarea
                      v-model="company.longDescription"
                      placeholder="Long Description"
                      rows="4"
                    />
                  </b-input-group>
                </div>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card-code>

        <b-card-code
          title="Social Links"
        >
          <b-row>
            <b-col md="6">
              <b-form-group
                label="Facebook"
              >
                <b-input-group class="input-group-merge">
                  <b-input-group-prepend is-text>
                    <feather-icon icon="FacebookIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    v-model="company.links.facebook"
                    placeholder="Facebook"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group
                label="Linkedin"
              >
                <b-input-group class="input-group-merge">
                  <b-input-group-prepend is-text>
                    <feather-icon icon="LinkedinIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    v-model="company.links.linkedin"
                    placeholder="Linkedin"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group
                label="Twitter"
              >
                <b-input-group class="input-group-merge">
                  <b-input-group-prepend is-text>
                    <feather-icon icon="TwitterIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    v-model="company.links.twitter"
                    placeholder="Twitter"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group
                label="Instagram"
              >
                <b-input-group class="input-group-merge">
                  <b-input-group-prepend is-text>
                    <feather-icon icon="InstagramIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    v-model="company.links.instagram"
                    placeholder="Instagram"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group
                label="Youtube"
              >
                <b-input-group class="input-group-merge">
                  <b-input-group-prepend is-text>
                    <feather-icon icon="YoutubeIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    v-model="company.links.youtube"
                    placeholder="Youtube"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group
                label="Tiktok"
              >
                <b-input-group class="input-group-merge">
                  <b-input-group-prepend is-text>
                    <span
                      class="iconify"
                      data-icon="simple-icons:tiktok"
                    />
                  </b-input-group-prepend>
                  <b-form-input
                    v-model="company.links.tiktok"
                    placeholder="Tiktok"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>

          </b-row>
        </b-card-code>

        <b-row>
          <b-col
            xs="12"
            class="text-center"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
              class="mr-1"
              :disabled="submit"
            >
              <template v-if="submit">
                <b-spinner
                  small
                  type="grow"
                />
                Loading...
              </template>
              <template v-else>
                Submit
              </template>
            </b-button>
          </b-col>
        </b-row>

      </b-form>
    </template>

    <b-modal
      v-model="showUserImageCropModal"
      centered
      title="Edit Image"
      :hide-footer="true"
    >
      <b-card-text>
        <section class="modal-card-body text-center">
          <vue-croppie
            ref="croppieRef"
            :enable-orientation="true"
            :mouse-wheel-zoom="true"
            :enable-resize="false"
            :boundary="{ width: 300, height: 300 }"
            :viewport="{ width: 200, height: 200, type: 'circle' }"
            @result="result"
          />
        </section>
        <footer class="modal-card-foot justify-content-center">
          <b-button
            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
            class="mr-1"
            variant="primary"
            pill
            @click="rotate(-90)"
          >
            Rotate Left
          </b-button>
          <b-button
            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
            class="mr-1"
            variant="info"
            pill
            @click="rotate(90)"
          >
            Rotate Right
          </b-button>
          <b-button
            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
            class="ml-0"
            variant="success"
            pill
            @click="crop"
          >
            Save Changes
          </b-button>
        </footer>
      </b-card-text>
    </b-modal>

  </div>
</template>

<script>
/* eslint-disable */
import Vue from 'vue';
import VueCroppie from 'vue-croppie';
import 'croppie/croppie.css' // import the croppie css manually
Vue.use(VueCroppie)

import {
  BRow, BCol, BAlert, BLink, BForm, BFormGroup, BFormInput, BButton, BInputGroup, BInputGroupPrepend, BFormDatepicker,
  BFormTextarea, BSpinner, BModal, BCardText, BFormTags,
} from 'bootstrap-vue'
import moment from 'moment'
// import { Icon } from '@iconify/vue'
import Ripple from 'vue-ripple-directive'
import { mapActions, mapGetters } from 'vuex'
import vSelect from 'vue-select'
import BCardCode from '@core/components/b-card-code'
import UserViewUserInfoCard from './users-view/UserViewUserInfoCard.vue'
import UserViewUserPlanCard from './users-view/UserViewUserPlanCard.vue'
import UserViewUserTimelineCard from './users-view/UserViewUserTimelineCard.vue'
import UserViewUserPermissionsCard from './users-view/UserViewUserPermissionsCard.vue'
import { countryArr, countryStates } from '@/data/countriesAndStates'

export default {
  components: {
    // Icon,
    BRow,
    BCol,
    BLink,
    BForm,
    BModal,
    BAlert,
    BButton,
    vSelect,
    BSpinner,
		BFormTags,
    BCardText,
    BCardCode,
    BFormInput,
    BFormGroup,
    BInputGroup,
    BFormTextarea,
    BFormDatepicker,
    BInputGroupPrepend,

    // Local Components
    UserViewUserInfoCard,
    UserViewUserPlanCard,
    UserViewUserTimelineCard,
    UserViewUserPermissionsCard,
  },
  directives: {
    Ripple,
  },
  props: ['slug'],
  data() {
    return {
        countryArr,
        states: [],
        submit: false,
        loaded: false,
        countryStates,
        subCategories: [],
        uploadedImage: null,
        showUserImageCropModal: false,
    }
  },
  computed: {
    ...mapGetters('companies', ['company', 'categories']),
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.getData()
      vm.getCompanyCategoriesAction()
    })
  },
  created() {
    if (!this.company) {
      this.getData()
    }
  },
  methods: {
    ...mapActions('companies', ['getCompanyDetailsAction', 'getCompanyCategoriesAction', 'updateCompanyAction']),
    pickUpClientImage () {
      this.$refs.uploadClientImage.click();
	  },
    result (output) {
      this.uploadedImage = output;
    },
    rotate (rotationAngle) {
      this.$refs.croppieRef.rotate(rotationAngle);
		},
    crop () {
      let options = {
          format: 'jpeg',
          circle: false,
      };

      this.$refs.croppieRef.result(options, output => {
        this.uploadedImage = output;
      });

      this.showUserImageCropModal = false;
    },
    handleClientImage (event) {
      const files = event.target.files
      let reader = new FileReader()
      reader.addEventListener("load", () => {
        this.$refs.croppieRef.bind({
          url: reader.result
        })
      })
      reader.readAsDataURL(files[0])
      this.showUserImageCropModal = true
    },
    getData() {
		this.getCompanyDetailsAction(this.slug).finally(() => {
			this.loaded = true

			if (this.company && this.company.industries) {
				const subCategories = this.company.industries.map(industry => industry.subCategories)
				this.subCategories = [].concat.apply([], subCategories)
			}

			if (this.company && this.company.establishedDate) {
				this.$store.state['companies'].company.establishedDate = moment(this.company.establishedDate, 'MM/DD/YYYY').format('YYYY-MM-DD')
			}

			if (this.company && this.company.location && this.company.location.country) {
				const country = this.capitalize(this.company.location.country)
				const index   = this.countryArr.indexOf(country)
				this.states   = this.countryStates[index].split('|')
			} else if (this.company && this.company.location && this.company.location.address) {
				const parts = this.company.location.address.split(', ')
				let country = parts.slice(-1).pop()

				if (country) {
					this.$store.state['companies'].company.location.country = country
					country = this.capitalize(country)
					const index   = this.countryArr.indexOf(country)
					if (index) {
						this.states   = this.countryStates[index + 1].split('|')
						const state = parts.slice(-2, -1).pop()
						if (state && this.states.includes(state)) {
							this.$store.state['companies'].company.location.state = state
						}
					}
				}
			}
		})
    },
    updateCompanyInfo() {
        this.submit = true

        const company = {
            // id: this.company.id,
            name: this.company.name,
            // slug: this.company.slug,
            // logo: ,
            email: this.company.email,
            industries: this.company.industries.map(industry => industry.name),
            subCategories: this.company.subCategories,
            website: this.company.website,
            phone: this.company.phone,
            country: this.company.location.country,
            // city: ,
            state: this.company.location.state,
            address: this.company.location.address,
            zip: this.company.location.zip,
            establishedDate: this.company.establishedDate,
            about: this.company.about,
            longDescription: this.company.longDescription,
            linkedin: this.company.links.linkedin,
            facebook: this.company.links.facebook,
            twitter: this.company.links.twitter,
            instagram: this.company.links.instagram,
            youtube: this.company.links.youtube,
            tiktok: this.company.links.tiktok,
            stockName: this.company.stockName,
            employeesNumber: this.company.employeesNumber,
        }

        if (this.uploadedImage) {
          company.logo = this.uploadedImage
        }

        this.updateCompanyAction({ slug: this.company.slug, data: company }).then(() => {
          this.showToast('Data Updated Successfully!')
        }).catch(({ response }) => {
          this.showToast(response.data.message, 'danger', 'AlertTriangleIcon')
        }).finally(() => {
            this.submit = false
        })
    },
    selectState(selectedOption) {
        const country = this.capitalize(selectedOption)
        const index   = this.countryArr.indexOf(country)
        this.states   = countryStates[index + 1].split('|')

        if (this.states && this.states.indexOf(this.company.location.state) == -1) {
            this.$store.state['companies'].company.location.state = null;
        }
    },
    setSubCategories() {
		this.subCategories = [];
		const subCategories = [...this.company.industries].map(mainIndustry => {
			let industry = {}

			if (typeof mainIndustry === 'string') { // Incase Previous Selected Values
				let data = this.categories.filter(category => category.name == (typeof mainIndustry == 'object' ? mainIndustry.name : mainIndustry));
				industry.name = typeof mainIndustry == 'object' ? mainIndustry.name : mainIndustry;
				industry.subCategories = [].concat.apply([], data.map(category => {
					return category.subCategories.map(subCategory => {
						return { name: subCategory }
					})
				}))
			} else {
				industry.name = mainIndustry.name;
				industry.subCategories = (mainIndustry.subCategories || mainIndustry.name.subCategories).map(subCategory => {
					if (typeof subCategory === 'string') {
						return { name: subCategory }
					} else {
						return subCategory
					}
				})
			}

			return industry;
		})

      // this.subCategories = subCategories.map(category => category.subCategories);
      // console.log({ subCategories: this.subCategories })

			const mainSubCategories = [].concat.apply([], subCategories.map(category => category.subCategories.map(subCategory => subCategory.name)));
            this.subCategories = mainSubCategories

			// Check That Selected Sub-Categories Exist In Selected Industries
			this.company.subCategories = this.company.subCategories.filter(subCategory => mainSubCategories.indexOf(subCategory) != -1)
        },
    capitalize(s) {
        if (typeof s !== 'string') return ''
        return s.charAt(0).toUpperCase() + s.slice(1)
    },
  },
}
</script>

<style lang="scss">
    @import '@core/scss/vue/libs/vue-select.scss';
</style>
